@import 'colors.scss';

.specificityWrapper {
  .button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background-color: #ffffff;

    &:hover {
      background-color: $grey300;
    }
  }

  .button.button {
    padding: 0 16px;
  }

  .logo {
    width: 21px;
    margin: 3px 0 0;
  }

  .buttonText {
    color: #000000;
    font-family: Averta;
    font-size: 16px;
    font-weight: 400;
  }
}
